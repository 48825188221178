import dynamicSiteConfig from '../../../../src/utils/dynamic/dynamicSiteConfig';

const customConfig = config => {
  /**
   * updates main config from dynamicSites config
   * @param  {object} config
   */
  dynamicSiteConfig(config);
};

export default customConfig;
